import axios from 'axios';

class Api {
  constructor() {
    if (!process.env.REACT_APP_ENV) throw new Error('Something is wrong!');

    axios.defaults.baseURL = `https://${process.env.REACT_APP_API_DOMAIN}/api`;
    // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access-token')}`;
    axios.interceptors.request.use((config) => {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem(
        'access-token',
      )}`;
      config.headers['Content-Type'] = 'application/json';
      config.headers['accept'] = 'application/json';

      return config;
    });
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          axios.defaults.headers.common['Authorization'] = '';
          localStorage.removeItem('access-token');
        }
        return Promise.reject(error);
      },
    );
  }

  auth = {
    login: (form) => axios.post('/auth/login', { ...form }),
    logout: () => axios.delete('/auth/logout'),
    getUser: () => axios.get('/auth/token'),
    getPublicUsers: () => axios.get('/auth/publicUser/list'),
  };

  user = {
    getUsers: (params) => axios.get('/auth/user/', { params }),
    findByIds: (params) => axios.get('/auth/user/findByIds', { params }),
    getUser: (id) => axios.get(`/auth/user/${id}`),
    createUser: (form) => axios.post('/auth/user', form),
    updateUser: (id, form) => axios.put(`/auth/user/${id}`, form),
    updateUserPass: (id, form) => axios.put(`/auth/user/${id}/password`, form),
    deleteUser: (id) => axios.delete(`/auth/user/${id}`),

    getUsersForMention: (type, id) =>
      axios.get(`/auth/access/${type}/${id}/users`),
    getAccesses: (type, id) => axios.get(`/auth/access/${type}/${id}`),
    grandAccess: (type, id, form) =>
      axios.post(`/auth/access/${type}/${id}`, form),
    updateAccess: (id, form) => axios.put(`/auth/access/${id}`, form),
    deleteAccess: (id) => axios.delete(`/auth/access/${id}`),
  };

  brand = {
    getBrands: (params) => axios.get('/corp/brand/', { params }),
    findByIds: (params) => axios.get('/corp/brand/findByIds', { params }),
    getBrand: (id) => axios.get(`/corp/brand/${id}`),
    createBrand: (form) => axios.post('/corp/brand', { ...form }),
    updateBrand: (id, form) => axios.put(`/corp/brand/${id}`, { ...form }),
    // updateNote: (id, form) => axios.put(`/corp/brand/${id}/note`, form),
    // updateName: (id, form) => axios.put(`/corp/brand/${id}/name`, form),
    // updateDCM: (id, form) => axios.put(`/corp/brand/${id}/DCM`, form),
    // updateAdAccounts: (id, form) => axios.put(`/corp/brand/${id}/adAccount`, form),
    deleteBrand: (id) => axios.delete(`/corp/brand/${id}`),
  };

  team = {
    getTeams: (params) => axios.get('/corp/team/', { params }),
    getTeam: (id) => axios.get(`/corp/team/${id}`),
    createTeam: (form) => axios.post('/corp/team', form),
    update: (id, form) => axios.put(`/corp/team/${id}`, form),
    // updateName: (id, form) => axios.put(`/corp/team/${id}/name`, form),
    deleteTeam: (id) => axios.delete(`/corp/team/${id}`),

    addPage: (teamId, form) => axios.post(`/corp/team/${teamId}/pages`, form),
    updatePage: (teamId, id, form) =>
      axios.put(`/corp/team/${teamId}/pages/${id}`, form),
    deletePage: (teamId, id) =>
      axios.delete(`/corp/team/${teamId}/pages/${id}`),
  };

  videobuilder = {
    getWorkers: (params) => axios.get('/videobuilder/worker/list', { params }),
    updateWorker: (params) => axios.post('/videobuilder/worker/update', params),
    listTemplates: (brandId) =>
      axios.get(`/videobuilder/templates/list/${brandId}`),
    getTemplateByName: (name) =>
      axios.get(`/videobuilder/templates/getByName/${name}`),
    uploadTemplate: (formData, func) =>
      axios.post(`/videobuilder/templates/add`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) =>
          func(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      }),
    submitRend: (data) => axios.post(`/videobuilder/worker/task`, data),
  };

  project = {
    getProjects: (params) => axios.get('/corp/project', { params }),
    getProject: (id) => axios.get(`/corp/project/${id}`),
    createProject: (form) => axios.post('/corp/project', { ...form }),
    updateProject: (id, form) => axios.put(`/corp/project/${id}`, { ...form }),
    // updateStatus: (id, form) => axios.put(`/corp/project/${id}/status`, { ...form }),
    deleteProject: (id) => axios.delete(`/corp/project/${id}`),
  };

  asset = {
    getFiles: (type, id, params) =>
      axios.get(`/asset/${type}/${id}`, { params }),
    getFile: (id) => axios.get(`/asset/${id}`),
    uploadFile: (type, id, formData, func) =>
      axios.post(`/asset/${type}/${id}`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) =>
          func(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      }),
    uploadAvatar: (type, id, formData) =>
      axios.post(`/asset/avatar/${type}/${id}`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      }),
    findAvatars: (type, ids) =>
      axios.get(`/asset/avatar/${type}`, { params: { ids } }),
    addLink: (type, id, form) => axios.post(`/asset/${type}/${id}/url`, form),
    uploadCreative: (type, id, formData, func) =>
      axios.post(`/asset/${type}/${id}/creative`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) =>
          func(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      }),
    updateCreative: (id, formData, func) =>
      axios.put(`/asset/${id}/creative`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) =>
          func(Math.round((progressEvent.loaded * 100) / progressEvent.total)),
      }),
    deleteAsset: (id) => axios.delete(`/asset/${id}`),

    getColorGrouos: (brandId) => axios.get(`/asset/colorGroup/${brandId}`),
    createColorGroup: (brandId, form) =>
      axios.post(`/asset/colorGroup/${brandId}`, form),
    updateColorGroup: (groupId, form) =>
      axios.put(`/asset/colorGroup/${groupId}`, form),
    deleteColorGroup: (groupId) => axios.delete(`/asset/colorGroup/${groupId}`),
    addColor: (groupId, form) => axios.post(`/asset/color/${groupId}`, form),
    updateColor: (groupId, colorId, form) =>
      axios.put(`/asset/color/${groupId}/${colorId}`, form),
    deleteColor: (groupId, colorId) =>
      axios.delete(`/asset/color/${groupId}/${colorId}`),
  };

  comment = {
    getComments: (type, id, params) =>
      axios.get(`/comment/${type}/${id}`, { params }),
    createComment: (type, id, form) =>
      axios.post(`/comment/${type}/${id}`, form),
    uploadAttachment: (type, id, formData) =>
      axios.put(`/comment/${type}/${id}`, formData, {
        headers: { 'content-type': 'multipart/form-data' },
      }),
    updateComment: (id, form) => axios.put(`/comment/${id}`, { ...form }),
    resolve: (id) => axios.put(`/comment/${id}/resolve`, {}),
    deleteComment: (id) => axios.delete(`/comment/${id}`),
    deleteAttachment: (id, tmpKey) =>
      axios.delete(`/comment/attachment/${id}`, { data: { tmpKey } }),
  };

  mail = {
    getMailTemplates: () => axios.get('/mail/templates/list'),
    updateEmail: (email) => axios.post('/mail/templates/edit', email),
  };
  tools = {
    updateAdImages: () => axios.get('/tools/scripts/updateAdImagesStrToObj'),
  };
}

export default new Api();
