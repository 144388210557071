import React from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import PrivateLayout from './layout';
import { getUser } from './../pages/login/slice';

const Login = Loadable({ loader: () => import('./../pages/login'), loading: () => null });

class Auth extends React.Component {
  componentDidMount() {
    if (localStorage.getItem('access-token') && !this.props.auth.user && !this.props.auth.fething && !this.props.auth.fetched) {
      this.props.dispatch(getUser());
    }
  }

  render() {
    if (this.props.auth.user && localStorage.getItem('access-token')) {
      return <PrivateLayout />;
    }
    
    if (this.props.auth.fetched || !localStorage.getItem('access-token')) {
      return <Route component={Login} />;
    }
  
    return null;
  }
}

export default connect(store => ({
  auth: store.auth,
}))(withRouter(Auth));
