import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from './../../api';
import { getAccesses } from './../../pages/user/slice';

export const getWorkers = createAsyncThunk(
  'videobuilder/get',
  async (filter, { dispatch }) => {
    const response = await api.videobuilder.getWorkers(filter);
    //const workers = [...new Set(response.data.items.map(p => p._id))];
    return response.data;
  }
);

export const videobuilder = createSlice({
  name: 'videobuilder',
  initialState: {
    asyncState: {},
    item: {},
    items: [],
    total: 0,
    fetching: false,
    deleting: {},
    error: null,
    uploadError: null,
    uploading: false,
    images: {},
  },
  reducers: {
    dismissError(state, action) {
      delete state.asyncState[action.payload]
    },
  },
  extraReducers: {
    //
    [getWorkers.pending]: state => ({ ...state, fetching: true, error: null }),
    [getWorkers.fulfilled]: (state, { payload }) => ({ ...state, items: payload, total: payload.total, fetching: false, error: null }),
    [getWorkers.rejected]: (state, { error }) => ({ ...state, fetching: false, error }),
  }
});

export const { dismissError } = videobuilder.actions;
export default videobuilder.reducer;
