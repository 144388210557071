import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from './../../api';
// import { findAvatars } from './../../pages/file/slice';

export const login = createAsyncThunk(
  'auth/login',
  async (form, { rejectWithValue }) => {
    try {
      const response = await api.auth.login(form);
      if (response.data.type !== 'admin') return rejectWithValue({ status: 401, message: 'Invalid credentials!' });
      localStorage.setItem('access-token', response.headers['access-token']);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (_, { dispatch }) => {
    const response = await api.auth.getUser();
    // dispatch(findAvatars({ key: `user/${response.data._id}`, type: 'user', ids: [response.data._id] }));
    if (response.data.type !== 'admin') {
      localStorage.removeItem('access-token');
      return null;
    }
    return response.data;
  }
);

export const auth = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    fetched: false,
    fetching: false,
    error: null
  },
  reducers: {
    logout: state => {
      localStorage.removeItem('access-token');
      return ({ ...state, user: null, fetched: true });
    },
    updateUserData: (state, { payload }) => {
      return ({ ...state, user: payload });
    },
  },
  extraReducers: {
    //
    [login.pending]: state => ({ ...state, fetching: true, error: null }),
    [login.fulfilled]: (state, action) => ({ ...state, user: action.payload, fetching: false, fetched: true, error: null }),
    [login.rejected]: (state, action) => ({ ...state, user: null, fetching: false, fetched: true, error: action.payload }),
    // 
    [getUser.pending]: state => ({ ...state, fetched: false, fetching: true, error: null }),
    [getUser.fulfilled]: (state, action) => ({ ...state, user: action.payload, fetched: true, error: null }),
    [getUser.rejected]: (state, action) => ({ ...state, user: null, error: action.error, fetched: true })
  }
});

export const { logout, updateUserData } = auth.actions;
export default auth.reducer;