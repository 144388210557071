import React from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Switch, Route, Link } from 'react-router-dom';
import SideBar from './sideBar';
import Avatar from './avatar';
import { logout } from './../pages/login/slice';

const User = Loadable({
  loader: () => import('./../pages/user'),
  loading: () => null,
});
const Guest = Loadable({
  loader: () => import('./../pages/guest'),
  loading: () => null,
});
const Brand = Loadable({
  loader: () => import('./../pages/brand'),
  loading: () => null,
});
const Team = Loadable({
  loader: () => import('./../pages/team'),
  loading: () => null,
});
const Videobuilder = Loadable({
  loader: () => import('./../pages/videobuilder'),
  loading: () => null,
});
const Profile = Loadable({
  loader: () => import('./../pages/profile'),
  loading: () => null,
});
const Project = Loadable({
  loader: () => import('./../pages/project/list'),
  loading: () => null,
});
const MailTemplates = Loadable({
  loader: () => import('./../pages/mailTemplates'),
  loading: () => null,
});
const Tools = Loadable({
  loader: () => import('./../pages/tools'),
  loading: () => null,
});
const Previews = Loadable({
  loader: () => import('./../pages/previews'),
  loading: () => null,
});

const Layout = ({ dispatch, auth }) => {
  return (
    <div className='container-fluid d-flex m-0 p-0'>
      <div className='sidebar'>
        <div className='ml-3 mt-3 mb-4'>
          <Link to='/'>
            <img
              src={`${process.env.PUBLIC_URL}/images/grid8logo.svg`}
              width='80'
              alt=''
            />
          </Link>
        </div>
        <SideBar />
      </div>
      <div className='content'>
        <div className='mb-3 d-flex justify-content-between topbar'>
          <div />
          <div>
            <Dropdown>
              <Dropdown.Toggle className='d-flex justify-content-center align-items-center bg-transparent border-0 text-dark'>
                <strong>{auth.user && auth.user.name}</strong>
                <Avatar
                  disableClick
                  type='user'
                  id={auth.user._id}
                  className='profile-img mr-2 ml-2'
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className='rounded-0'>
                <Link to='/profile' className='text-dark dropdown-item'>
                  Profile
                </Link>
                <Dropdown.Item onClick={() => dispatch(logout())}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className='p-0'>
          <Switch>
            <Route exact path='/' component={() => 'dh'} />
            <Route path='/user/:action(create|view)?/:id?' component={User} />
            <Route path='/guest/:action(create|view)?/:id?' component={Guest} />
            <Route
              path='/brand/:action(create|edit|view)?/:id?'
              component={Brand}
            />
            <Route path='/team/:action(create|view)?/:id?' component={Team} />
            <Route
              path='/videobuilder/:action(create|view)?/:id?'
              component={Videobuilder}
            />
            <Route
              path='/project/:action(create|view)?/:projectId?'
              component={Project}
            />
            <Route path='/profile' component={Profile} />
            <Route path='/mail' component={MailTemplates} />
            <Route path='/tools' component={Tools} />
            <Route path='/reviewers' component={Previews} />
            <Route path='*'>404</Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default connect((store) => ({
  auth: store.auth,
}))(Layout);
