import { configureStore, combineReducers } from '@reduxjs/toolkit';
import auth from './pages/login/slice';
import user from './pages/user/slice';
import brand from './pages/brand/slice';
import team from './pages/team/slice';
import videobuilder from './pages/videobuilder/slice';
import project from './pages/project/slice';
import file from './pages/file/slice';
import comment from './pages/comment/slice';
import color from './pages/color/slice';

export default configureStore({
  reducer: combineReducers({
    auth,
    user,
    brand,
    team,
    videobuilder,
    project,
    file,
    comment,
    color,
  }),
  devTools: process.env.REACT_APP_ENV === 'development',
});
