import React from 'react';
import { Link, Route } from 'react-router-dom';

const CustomLink = ({ activeStyle, children, to, activeOnlyWhenExact }) => (
  <Route
    path={to}
    exact={activeOnlyWhenExact}
    children={(
      { match }, // eslint-disable-line
    ) => (
      <Link
        to={to}
        className={match ? 'active nav-link' : 'nav-link'}
        style={match && activeStyle}
      >
        {children}
      </Link>
    )}
  />
);

const SideBar = () => {
  return (
    <React.Fragment>
      <nav className='nav flex-column'>
        <CustomLink to='/' activeOnlyWhenExact>
          Dashboard
        </CustomLink>
        {/*<CustomLink to='/brand'>Brands</CustomLink>
        <CustomLink to='/team'>Teams</CustomLink>
        <CustomLink to='/project'>Project</CustomLink>
        <CustomLink to='/guest'>Guests</CustomLink>*/}
        <CustomLink to='/user'>Users</CustomLink>
        <CustomLink to='/reviewers'>Reviewers</CustomLink>
      </nav>
      <div className='separator m-0 ml-3 text-muted pt-3 mb-3 pb-2 font-weight-bold'>
        Services
      </div>
      <nav className='nav flex-column'>
        <CustomLink to='/videobuilder'>Videobuilder manager</CustomLink>
        <CustomLink to='/mail'>Mail Templates</CustomLink>
        <CustomLink to='/tools'>Tools</CustomLink>
        {/*<CustomLink to="/google">Google</CustomLink>
        <CustomLink to="/facebook">Facebook</CustomLink>
        <CustomLink to="/streamingads">StreamingAds</CustomLink>
        <CustomLink to="/feedcapture">FeedCapture</CustomLink>
        <CustomLink to="/flexfeed">FlexFeed</CustomLink>*/}
      </nav>
    </React.Fragment>
  );
};

export default SideBar;
