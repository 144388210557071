import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import store from './store';
import Auth from './helpers/auth';
import './scss/main.scss';

library.add(fas);
String.prototype.trunc = function(n) {
  return this.substr(0, n-1) + (this.length > n ? '...' : '');
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Auth />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

