import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

const Avatar = ({ type, id, className, file }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    getImg(type, id);
  }, [type, id, file?.avatars[type][id]?.createdAt]);

  const projectrandomizer = (str) => {
    const obj = {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      6: 1,
      7: 2,
      8: 4,
      9: 5,
      0: 1,
      a: 2,
      b: 3,
      c: 4,
      d: 5,
      e: 1,
      f: 2,
    };
    return obj[str ? str[str.length - 1] : 1];
  };

  const getImg = useCallback(
    async (type, id) => {
      try {
        if (file?.avatars[type][id]?.createdAt) {
          setUrl(
            `${process.env.REACT_APP_S3_URL}/${id}.png?x=${Math.random()}`
          );
          return;
        }
        await axios.head(`${process.env.REACT_APP_S3_URL}/${id}.png`, {
          transformRequest: (_, headers) => {
            delete headers['Authorization'];
          },
        });
        setUrl(`${process.env.REACT_APP_S3_URL}/${id}.png?x=${Math.random()}`);
      } catch (e) {
        setUrl(
          `${process.env.PUBLIC_URL}/images/${
            type === 'project'
              ? `project_default_${projectrandomizer(id)}.png`
              : `${type}_default.svg`
          }`
        );
      }
    },
    [type, id, file?.avatars[type][id]?.createdAt]
  );

  return (
    <div className={className} key={file?.avatars[type][id]?.createdAt}>
      {url && <img src={`${url}`} />}
    </div>
  );
};

export default connect((store) => ({
  file: store.file,
}))(Avatar);
